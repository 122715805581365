<template>
  <div class="preview" 
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header dark">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/" >
          <el-breadcrumb-item
            v-for="(n, i) in space.routes"
            :key="i">
            <span v-if="n.disable" style="cursor: pointer; color: #1eaaff" @click="jumpSpace(n)">
            {{n.name}}</span>
            <span v-else style="color: #b3bfc7">
            {{n.name}}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame dark">
          <div class="line"
               style="padding:24px 0 16px">
            <div class="right">
              <!-- <el-button type="primary"
                         @click="createProject()">新增项目</el-button> -->
              <el-button type="primary"
                         v-if="addAuth"
                         @click="createSpace()">新增子空间</el-button>
            </div>
          </div>
          <el-table class="space-table"
                    :data="tableData"
                    row-key="id"
                    :show-header="false">
            <el-table-column>
              <template #default="scope">
                <div class="space-name">
                  <i class="icomoon-bookmark" v-if="scope.row.type == 2" style="width: 30px;"></i>
                  <i class="icomoon-folder-open" v-if="scope.row.type == 1" style="width: 30px;margin-top: 4px;"></i>
                  <img :src="scope.row.picture"
                       alt="封面">
                  <span @click="details(scope.row)">{{scope.row.name}}</span>
                  <p class="space-lock icomoon-lock"></p>
                  <b class="space-owner"
                     v-if="scope.row.roleName">{{scope.row.roleName}}</b>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="48">
              <template #default="scope">
                <div class="space-cog icomoon-cog"
                     v-if="editAuth"
                     @click="settingRoute(scope.row)"></div>
              </template>
            </el-table-column>
            <el-table-column width="72">
              <template #default="scope">
                <div class="space-label">
                  <i class="icomoon-folder-open"></i>
                  {{ scope.row.spaceCount }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="72">
              <template #default="scope">
                <div class="space-label">
                  <i class="icomoon-bookmark"></i>
                  {{ scope.row.prjCount }}
                </div>
              </template>
            </el-table-column>
            <el-table-column width="72">
              <template #default="scope">
                <div class="space-label">
                  <i class="icomoon-users"></i>
                  {{ scope.row.memberCount }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <empty v-if="tableData.length == 0" info="空间"
               style="height:70vh"></empty>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref , getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'underscore';
import { btnStatus, getSpaceOrPrjDetails, jumpRouteSetup } from 'common/authBtn';
import Empty from 'components/common/forms/Empty';

export default {
  name: "SpaceDetail",
  components: {
    Empty,
  },
  setup (props, {emit}) {
    const $router = useRouter();
    const $store = useStore();
    let space = ref({});
    let tableData = ref([]), recordList = ref([]), title = ref('');
    let addAuth = ref(false), editAuth = ref(false), loading = ref(true);
    const defaultPic = require('assets/images/frame/default-space.svg');
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;
     //权限校验
    const filterRoleList = (callback) => {
      btnStatus(space.value.id, $store, (result) => {
        addAuth.value = result.addAuth;
        editAuth.value = result.editAuth;
        if (callback)
          callback();
      });
    };
    const createSpace = () => {
      $router.push({ name: 'SpaceAdd', params: { pid: space.value.id, pname: space.value.name } });
    };

    const getChildren = (obj) => {
      let children = _.filter(recordList.value, (v) => {
        return obj.id == v.pid;
      })
      if (children && children.length > 0) {
        obj.children = children;
        _.each(obj.children, (v) => {
          v = getChildren(v);
        })
      }
      return obj;
    };
    const setting = () => {
      $router.push('/Main/Setting');
    };

    const settingRoute = (item) => {
      $api.sp.setCurrentSpace({id: item.id}).then(res => {
          setting();
      }) 
    };

    const getDetail = () => {
      getSpaceOrPrjDetails($store).then((res) => {
        loading.value = false;
        if (res) {
          title.value = res.name;
          space.value = res;
          filterRoleList();
          getDataFn();
        }
      })
    }

    const getDataFn = () => {
      tableData.value = [];
      $api.sp.getSpaceById({id: space.value.id}).then((res) => {
        _.each(res, (v) => {
          if(v.pic && v.pic != '-1')
            v.picture = '/file/'+ v.pic;
          else 
            v.picture = defaultPic;
          switch (parseInt(v.roleId)) {
            case 1:
              v.roleName = '拥有者';
              break;
            case 2:
              v.roleName = '管理员';
              break;
            case 3:
              v.roleName = '配置人员';
              break;
            case 4:
              v.roleName = '开发人员';
              break;
            case 5:
              v.roleName = '游客';
              break;  
            default:
              break;
          }
        })
        recordList.value = res;
        let root = _.filter(res, (v) => {
          return space.value.id == v.pid;
        })
        if (root && root.length > 0) {
          _.each(root, (v) => {
            v = getChildren(v);
          })
        }
        tableData.value = root;
      })
    };

    const details = (item) => {
      jumpRouteSetup(emit, item).then(() => {
        if(item.type == 1) 
          getDetail();
        else 
          $router.push('/Main/Preview');
      })
    };

    const jumpSpace = (n) => {
      if(space.value.id != n.id) {
        jumpRouteSetup(emit, n).then(() => {
          getDetail();
        })
      }
    };

    onMounted(() => {
      getDetail();
    })

    return {
      space,
      loading,
      addAuth,
      editAuth,
      tableData,
      
      details,
      createSpace,
      settingRoute,
      jumpSpace,
    };
  },
}
</script>


<style lang="scss" scoped>
.space-table {
  width: 100%;

  .space-label {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;

    i {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      margin-right: 4px;
      color: inherit;
    }
  }

  .space-cog {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    cursor: pointer;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);

    &:hover {
      color: rgb(30, 170, 255);
    }
  }

  .space-name {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin-right: 12px;
    }

    span {
      max-width: 320px;
      line-height: 24px;
      font-size: 16px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 12px;
      color: #fff;
      cursor: pointer;

      &:hover {
        color: rgb(30, 170, 255);
      }
    }

    p {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      cursor: pointer;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.4);
      margin: 0 12px 0 0;
    }

    b {
      height: 24px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      font-size: 14px;
      font-weight: normal;
      color: rgba(255, 255, 255, 0.4);
    }
  }
}
</style>